var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ResidentCardError } from '../../../../libs/errors';
import { FlatType, ResidentOwnershipType, ResidentType } from '../../../../libs/types';
import { fromJsonDate } from '../../elements/DateInput';
import { AuthorizedUser } from '../../logic/AuthorizedUser';
import { LockProcessor } from '../../logic/LockProcessor';
import { ROUTES } from '../../types/Routes';
import { ObjectStatus } from '../../types/Types';
import { errorPopup, infoPopup } from '../Popup/PopupController';
import { RegisteredResidents } from './RegisteredResidents';
import { ResidentFieldKeys } from './ResidentFieldKeys';
import ResidentViewStep1 from './ResidentViewStep1';
import ResidentViewStep2 from './ResidentViewStep2';
export const ResidentView = () => {
    const { id: residentId } = useParams();
    if (!residentId)
        return _jsx(Navigate, { to: ROUTES.RESIDENTS_REGISTER, replace: true });
    const navigate = useNavigate();
    const [resident, setResident] = useState({
        type: ResidentType.INDIVIDUAL,
        name: '',
        sex: '',
        phones: [],
        links: [],
        tags: [],
        inn: '',
        snils: '',
        comment: '',
        email: '',
        surname: '',
        patronymic: '',
        extId: '',
        hasLegendaHomeAccess: true
    });
    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState([]);
    const [links, setLinks] = useState(null);
    useEffect(() => {
        if (residentId !== "new")
            loadResidentData();
        else {
            const newLink = {
                status: ObjectStatus.ACTIVE,
                houseId: '',
                number: '',
                ownership: ResidentOwnershipType.OWNER,
                piece: '',
                type: FlatType.FLAT,
                documentNumber: '',
                documentType: ''
            };
            setLinks([newLink]);
        }
    }, [residentId]);
    function lockData(links, resident) {
        const locks = [
            { id: resident.id, className: "residents" },
            ...links.map(l => ({
                id: l.id,
                className: "r2fs"
            }))
        ];
        return LockProcessor.lock(locks);
    }
    function loadResidentData() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const req = AuthorizedUser.getRequest('/registry/resident', 'GET');
                req.params.id = residentId;
                const data = yield req.send();
                const doc = (_a = data.documents) === null || _a === void 0 ? void 0 : _a[0];
                const r2fs = data.r2fs;
                const residentData = {
                    id: data.id,
                    type: data.type,
                    name: data.name,
                    sex: data.sex,
                    phones: data.phones,
                    cars: data.cars || [],
                    links: [],
                    pets: data.pets || [],
                    tags: data.tags || [],
                    inn: data.inn || '',
                    snils: data.snils || '',
                    comment: data.comment || '',
                    birthDate: data.birth ? fromJsonDate(data.birth) : undefined,
                    email: data.email || '',
                    surname: data.surname || '',
                    patronymic: data.patronymic || '',
                    extId: data.extId || '',
                    hasLegendaHomeAccess: data.hasLegendaHomeAccess,
                    documents: doc ? [{
                            type: doc.type,
                            number: doc.number,
                            series: doc.series,
                            issuedBy: doc.issuedBy,
                            issuingDate: doc.issuingDate ? fromJsonDate(doc.issuingDate) : undefined
                        }] : []
                };
                const links = Array.isArray(r2fs) ? r2fs
                    .filter(r2f => r2f.status === ObjectStatus.ACTIVE)
                    .map(r2f => {
                    var _a, _b, _c;
                    return ({
                        status: r2f.status,
                        flatId: r2f.flat.id,
                        houseId: r2f.house.id,
                        number: r2f.flat.number,
                        ownership: r2f.type,
                        piece: r2f.share || '',
                        type: r2f.flat.type,
                        id: r2f.id,
                        documentDate: fromJsonDate((_a = r2f.ownershipDocument) === null || _a === void 0 ? void 0 : _a.issuingDate),
                        documentNumber: (_b = r2f.ownershipDocument) === null || _b === void 0 ? void 0 : _b.number,
                        documentType: (_c = r2f.ownershipDocument) === null || _c === void 0 ? void 0 : _c.type
                    });
                }) : [];
                setResident(residentData);
                setLinks(links);
                yield lockData(links, residentData);
            }
            catch (error) {
                console.error(error);
                throw new Error('Ошибка при загрузке данных жителя');
            }
        });
    }
    function addNewR2F() {
        const newLink = {
            status: ObjectStatus.ACTIVE,
            houseId: '',
            number: '',
            ownership: ResidentOwnershipType.OWNER,
            piece: '',
            type: FlatType.FLAT,
            documentNumber: '',
            documentType: ''
        };
        setLinks((prevState) => {
            if (!prevState)
                return [newLink];
            return [
                ...prevState,
                newLink
            ];
        });
    }
    ;
    function onValueChanged(key, val) {
        setResident(prevState => (Object.assign(Object.assign({}, prevState), { [key]: val })));
    }
    function onLinkValueChanged(index, key, val) {
        links[index][key] = val;
        setLinks([...links]);
    }
    function parseShare(str) {
        const spl = str.split("/");
        if (spl.length !== 2)
            return parseFloat(str);
        return parseInt(spl[0]) / parseInt(spl[1]);
    }
    function processShareErrors(str) {
        if (!str)
            return;
        const piece = parseShare(str);
        if (isNaN(piece)) {
            return 'Неверная доля собственника';
        }
        else if (piece <= 0) {
            return 'Доля собственника не может быть 0';
        }
        else if (piece > 1) {
            return 'Доля собственника не может быть больше 1';
        }
    }
    const validateStep1Fields = () => {
        const newErrors = [];
        resident.links.forEach((r2f, index) => {
            if (!r2f.number) {
                newErrors.push({
                    index,
                    field: ResidentFieldKeys.NUMBER,
                    message: 'Номер помещения',
                });
            }
            if (!r2f.houseId) {
                newErrors.push({
                    index,
                    field: ResidentFieldKeys.HOUSE,
                    message: 'Дом',
                });
            }
            if (!r2f.type) {
                newErrors.push({
                    index,
                    field: ResidentFieldKeys.SPACE_TYPE,
                    message: 'Тип помещения',
                });
            }
            if (!r2f.ownership) {
                newErrors.push({
                    index,
                    field: ResidentFieldKeys.OWNERSHIP,
                    message: 'Отношение к помещению',
                });
            }
            const message = processShareErrors(r2f.piece);
            if (message) {
                newErrors.push({
                    index,
                    field: ResidentFieldKeys.SHARE,
                    message
                });
            }
        });
        if (newErrors.length)
            throw newErrors;
    };
    const validateStep2Fields = (resident) => {
        const newErrors = [];
        switch (resident.type) {
            case ResidentType.BUSINESS:
                if (!resident.name) {
                    newErrors.push({
                        index: 0,
                        field: ResidentFieldKeys.NAME,
                        message: 'Наименование',
                    });
                }
                break;
            default:
                if (!resident.name) {
                    newErrors.push({
                        index: 0,
                        field: ResidentFieldKeys.NAME,
                        message: 'Имя',
                    });
                }
                if (!resident.surname) {
                    newErrors.push({
                        index: 0,
                        field: ResidentFieldKeys.SURNAME,
                        message: 'Фамилия',
                    });
                }
                if (!resident.sex) {
                    newErrors.push({
                        index: 0,
                        field: ResidentFieldKeys.GENDER,
                        message: 'Пол',
                    });
                }
                if (!resident.birthDate) {
                    newErrors.push({
                        index: 0,
                        field: ResidentFieldKeys.BIRTH_DATE,
                        message: 'Дата рождения',
                    });
                }
                break;
        }
        if (newErrors.length)
            throw newErrors;
    };
    const handleBackToStep1 = () => {
        setStep(1);
    };
    function validateLinks() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                setErrors([]);
                resident.links = links;
                validateStep1Fields();
                yield sendValidateDataResponse();
                setStep(2);
            }
            catch (e) {
                processErrorFields(e);
            }
        });
    }
    function processBusinessErrors(e) {
        var _a;
        const response = (_a = e.response) === null || _a === void 0 ? void 0 : _a.data;
        if (!response || !ResidentCardError.is(response))
            throw e;
        const errors = response.data;
        if (errors)
            throw errors;
        throw e;
    }
    function processErrorFields(e) {
        if (!Array.isArray(e))
            return errorPopup(e);
        setErrors(e);
    }
    function sendValidateDataResponse() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const req = AuthorizedUser.getRequest('/registry/resident/validate', 'post');
                req.method = 'POST';
                return yield req.send(resident);
            }
            catch (e) {
                processBusinessErrors(e);
            }
        });
    }
    function sendSaveResidentRequest() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const req = AuthorizedUser.getRequest('/registry/resident', 'post');
                req.method = 'POST';
                return yield req.send(resident);
            }
            catch (e) {
                processBusinessErrors(e);
            }
        });
    }
    function saveResidentCard() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                setErrors([]);
                if (resident.comment) {
                    resident.additionalInfo = resident.comment;
                    delete resident.comment;
                }
                if (resident.type === ResidentType.BUSINESS) {
                    delete resident.sex;
                }
                resident.links = links;
                validateStep2Fields(resident);
                yield sendSaveResidentRequest();
                goBack();
                infoPopup('Пользователь сохранён');
            }
            catch (e) {
                processErrorFields(e);
            }
        });
    }
    const getErrorMessage = (field) => {
        const error = errors.find((err) => err.field === field);
        return error ? error.message : '';
    };
    function renderLinks() {
        if (!Array.isArray(links))
            return null;
        return links.map((link, i) => _jsx(ResidentViewStep1, { link: link, index: i, handleLinkChange: (key, val) => {
                onLinkValueChanged(i, key, val);
            }, errors: errors }, i));
    }
    function getSuitableLinks() {
        if (!links)
            return [];
        return links.filter(l => l.flatId)
            .map(l => l.flatId);
    }
    function renderErrorsDiv() {
        if (!errors.length)
            return null;
        const uniqueErrors = Array.from(new Set(errors.map(e => e.message)));
        return _jsx("div", Object.assign({ className: "errors-container" }, { children: _jsxs("div", Object.assign({ className: "errors" }, { children: [_jsx("span", { children: "\u0414\u0430\u043D\u043D\u044B\u0435 \u043F\u043E\u043B\u044F \u0434\u043E\u043B\u0436\u043D\u044B \u0431\u044B\u0442\u044C \u0437\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u044B" }), _jsx("ul", { children: uniqueErrors.map((message, index) => (_jsxs("li", { children: [getErrorMessage(message), message] }, index))) })] })) }));
    }
    function goBack() {
        navigate(-1);
    }
    function renderStep1() {
        return _jsxs("div", Object.assign({ id: "add-resident-step1" }, { children: [_jsxs("div", Object.assign({ className: "workspace-padding" }, { children: [_jsxs("div", { children: [_jsx("p", { children: "\u0421\u0442\u0430\u0442\u0443\u0441 \u0438 \u043F\u0440\u0430\u0432\u043E \u0441\u043E\u0431\u0441\u0442\u0432\u0435\u043D\u043D\u043E\u0441\u0442\u0438" }), _jsx("div", {})] }), _jsxs("div", { children: [_jsx("p", { children: "\u041B\u0438\u0447\u043D\u044B\u0435 \u0434\u0430\u043D\u043D\u044B\u0435" }), _jsx("div", {})] })] })), _jsxs("div", Object.assign({ className: "frame workspace-padding flex row space" }, { children: [_jsxs("div", Object.assign({ className: "flex-list-gap" }, { children: [_jsx("h2", { children: "\u0428\u0430\u0433 1 \u0438\u0437 2: \u0414\u0430\u043D\u043D\u044B\u0435 \u043F\u043E \u043F\u043E\u043C\u0435\u0449\u0435\u043D\u0438\u044E" }), renderLinks(), _jsx("div", Object.assign({ className: "text-button add highlight", onClick: addNewR2F }, { children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043F\u043E\u043C\u0435\u0449\u0435\u043D\u0438\u0435" }))] })), _jsxs("div", Object.assign({ className: "flex-list-gap" }, { children: [_jsxs("div", Object.assign({ className: "button-container" }, { children: [_jsx("button", Object.assign({ onClick: goBack }, { children: "\u041E\u0442\u043C\u0435\u043D\u0430" })), _jsx("button", Object.assign({ className: "important save-button", onClick: validateLinks }, { children: "\u0414\u0430\u043B\u0435\u0435" }))] })), renderErrorsDiv(), _jsx(RegisteredResidents, { errors: errors, flatIds: getSuitableLinks() })] }))] }))] }));
    }
    function renderStep2() {
        return _jsx("div", Object.assign({ id: "add-resident-step2" }, { children: _jsxs("div", Object.assign({ className: "frame workspace-padding flex row space" }, { children: [_jsxs("div", Object.assign({ className: "flex-list-gap" }, { children: [_jsx("h2", { children: "\u0428\u0430\u0433 2 \u0438\u0437 2: \u0414\u0430\u043D\u043D\u044B\u0435 \u0441\u043E\u0431\u0441\u0442\u0432\u0435\u043D\u043D\u0438\u043A\u0430" }), _jsx(ResidentViewStep2, { errors: errors, resident: resident, handleChange: onValueChanged })] })), _jsxs("div", Object.assign({ className: "flex-list-gap" }, { children: [_jsxs("div", Object.assign({ className: "button-container" }, { children: [_jsx("button", Object.assign({ type: "button", onClick: handleBackToStep1 }, { children: "\u041D\u0430\u0437\u0430\u0434" })), _jsx("button", Object.assign({ className: "important save-button", onClick: saveResidentCard }, { children: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C" }))] })), renderErrorsDiv()] }))] })) }));
    }
    return (_jsx("div", Object.assign({ id: "workspace", className: "grey" }, { children: _jsx("div", Object.assign({ id: "add-resident-view" }, { children: step === 1 ? renderStep1() : renderStep2() })) })));
};
