import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
const ToTopBtn = ({}) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const handleClick = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    window.addEventListener('scroll', () => {
        const scrolledY = window.scrollY;
        setIsScrolled(scrolledY > 0);
    });
    return (_jsx("button", Object.assign({ className: `to-top-button${isScrolled ? ' show' : ''}`, type: "button", onClick: handleClick }, { children: _jsx("svg", Object.assign({ viewBox: "0 0 384 512" }, { children: _jsx("path", { d: "M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" }) })) })));
};
export default ToTopBtn;
