export function toInputDateString(date = new Date()) {
    const f = (v) => (v.length === 1 ? '0' + v : v);
    return `${date.getFullYear()}-${f((date.getMonth() + 1).toString())}-${f(date.getDate().toString())}`;
}
export function toDateString(date = new Date()) {
    const f = (v) => (v.length === 1 ? '0' + v : v);
    return `${f(date.getDate().toString())}.${f((date.getMonth() + 1).toString())}.${date.getFullYear()}`;
}
export function convertToDateInputFormat(isoDateStr) {
    const date = new Date(isoDateStr);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}
export function parseDate(dateLike) {
    return new Date(Date.parse(dateLike));
}
