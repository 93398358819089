export function queryValToArray(val) {
    if (typeof val !== 'string')
        return;
    const arr = val.split(',');
    if (arr.length > 0) {
        return arr;
    }
    else {
        return val;
    }
}
