import { triggerEvent } from "./HtmxUtils";
export class WsController {
    /**
     * @deprecated
     */
    static initWsFromGlobal() {
        // @ts-ignore
        this._ws = WS;
    }
    static getElementByIdOrThrow(id) {
        const element = document.getElementById(id);
        if (!element)
            throw new Error(`No ${id} found`);
        return element;
    }
    /**
     * Method for sending token data from react to htmx client
     * @deprecated
     */
    static submitTokens(user) {
        if (!this._ws)
            throw new Error("No ws assigned");
        this.getElementByIdOrThrow("token").value = user.token;
        this.getElementByIdOrThrow("refreshToken").value = user.refresh;
        this.getElementByIdOrThrow("expiration").value = user.expiration.toDateString();
        this.getElementByIdOrThrow("refreshExpiration").value = user.refreshExpiration.toDateString();
        triggerEvent("hidden-token-form", "send-tokens");
    }
    /**
    * @deprecated
    */
    static submitForUser(user) {
        this.submitTokens(user);
    }
}
