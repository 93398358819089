import { AuthError } from "./AuthError";
import { AuthErrorCodes } from "./AuthErrorCodes";
export class TokenExpiredError extends AuthError {
    constructor(message = "Token is expired") {
        super(message, AuthErrorCodes.TOKEN_EXPIRED);
    }
    static is(e) {
        return AuthError.is(e) &&
            e.code === AuthErrorCodes.TOKEN_EXPIRED;
    }
}
