var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ClientRequest } from "./ClientRequest";
import { LocalStorage } from "./LocalStorage";
import { WhoAmIApi } from "../../../libs/lightApi";
import { AuthorizedRequest } from "./AuthorizedRequest";
export class AuthorizedUser {
    static get instance() {
        return this._instance;
    }
    static remove() {
        this._instance = null;
    }
    static isTokenExpired(expiration) {
        return expiration.getTime() < Date.now();
    }
    static expirationToDate(exp) {
        return new Date(Date.now() + exp);
    }
    static getRequest(path, method = 'GET') {
        return new AuthorizedRequest(path, method);
    }
    get token() {
        return LocalStorage.token;
    }
    set token(token) {
        LocalStorage.token = token;
    }
    get refresh() {
        return LocalStorage.refreshToken;
    }
    set refresh(token) {
        LocalStorage.refreshToken = token;
    }
    get expiration() {
        return LocalStorage.expiration;
    }
    set expiration(date) {
        LocalStorage.expiration = date;
    }
    get refreshExpiration() {
        return LocalStorage.refreshTokenExpiration;
    }
    set refreshExpiration(date) {
        LocalStorage.refreshTokenExpiration = date;
    }
    constructor() {
        this.data = null;
        this.isLogined = false;
        if (AuthorizedUser._instance) {
            throw new Error("Another auth instance found. Logout before creating another one");
        }
        AuthorizedUser._instance = this;
    }
    isTokenExpired() {
        if (!this.expiration)
            throw new Error("No expiration found");
        return AuthorizedUser.isTokenExpired(this.expiration);
    }
    isRefreshTokenExpired() {
        if (!this.refreshExpiration)
            throw new Error("No refresh expiration found");
        return AuthorizedUser.isTokenExpired(this.refreshExpiration);
    }
    sendWhoAmIRequest() {
        return __awaiter(this, void 0, void 0, function* () {
            return new AuthorizedRequest(WhoAmIApi.path, "GET").send();
        });
    }
    logout() {
        LocalStorage.clear();
        AuthorizedUser._instance = null;
    }
    getData() {
        return this.data;
    }
    getRole() {
        var _a;
        return (_a = this.data) === null || _a === void 0 ? void 0 : _a.role;
    }
    login(login, password) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield new ClientRequest("/login", "POST")
                .send({
                login, password
            });
            this.token = result.token;
            this.refresh = result.refreshToken;
            this.expiration = AuthorizedUser.expirationToDate(result.expiration);
            this.refreshExpiration = AuthorizedUser.expirationToDate(result.refreshTokenExpiration);
        });
    }
    refreshToken() {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.sendRefreshRequest();
            this.token = response.token;
            this.expiration = AuthorizedUser.expirationToDate(response.expiration);
        });
    }
    whoAmI() {
        return __awaiter(this, void 0, void 0, function* () {
            this.data = yield this.sendWhoAmIRequest();
        });
    }
    sendRefreshRequest() {
        const req = new ClientRequest("/refresh", "POST");
        const refreshToken = this.refresh;
        if (!refreshToken)
            throw new Error("No refresh token found");
        return req.send({
            refreshToken
        });
    }
    isUserAuthValid() {
        return this.token && this.expiration && this.refresh && this.refreshExpiration;
    }
}
AuthorizedUser._instance = null;
