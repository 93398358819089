import { ResidentCardError } from "./ResidentCardError";
import { ResidentCardErrorCodes } from "./ResidentCardErrorCodes";
export class NoFlatsSelectedError extends ResidentCardError {
    constructor(message = "No flats selected") {
        super(message, ResidentCardErrorCodes.NO_FLATS_SELECTED);
    }
    static is(e) {
        return ResidentCardError.is(e) &&
            e.code === ResidentCardErrorCodes.NO_FLATS_SELECTED;
    }
}
