import { BusinesError } from "../BusinesError";
import { ErrorGroups } from "../ErrorGroups";
import { HttpErrorCodes } from "../HttpErrorCodes";
export class AuthError extends BusinesError {
    constructor(message, code) {
        super(message, code, ErrorGroups.AUTH, HttpErrorCodes.UNAUTHORIZED);
    }
    static is(e) {
        return BusinesError.is(e) &&
            ErrorGroups.AUTH === e.group;
    }
}
