import { AuthError } from "./AuthError";
import { AuthErrorCodes } from "./AuthErrorCodes";
export class AccessTemporaryBlocked extends AuthError {
    constructor(message = "Access is temporary blocked") {
        super(message, AuthErrorCodes.ACCESS_BLOCKED_TEMPORARY);
    }
    static is(e) {
        return AuthError.is(e) &&
            e.code === AuthErrorCodes.ACCESS_BLOCKED_TEMPORARY;
    }
}
