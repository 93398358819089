import { RoleType } from "../../../types";
function roleToRolePriority(role) {
    switch (role) {
        case RoleType.SYSTEM:
            return 0;
        case RoleType.SUPER_ADMIN:
            return 1;
        case RoleType.ADMIN:
            return 2;
        case RoleType.MANAGER:
            return 3;
        case RoleType.READ_ONLY:
            return 4;
        case RoleType.USER:
            return 5;
    }
}
export function doesSatisfyTheRole(requiredRole, actualRole) {
    if (!actualRole)
        return false;
    if (!requiredRole)
        return true;
    return roleToRolePriority(requiredRole) >= roleToRolePriority(actualRole);
}
