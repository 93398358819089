var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { AuthorizedUser } from '../../logic/AuthorizedUser';
import RegisteredResident from './RegisteredResident';
import { FlatType, } from '../../types/Types';
function getFlatTypeShortName(type) {
    switch (type) {
        case FlatType.FLAT:
            return 'кв.';
        case FlatType.STORAGE:
            return 'кл.';
        case FlatType.COMMERCE:
            return 'ком.';
        case FlatType.PARKING:
            return 'маш.';
        default:
            return '';
    }
}
export const RegisteredResidentsByFlat = ({ flatId }) => {
    const [registeredResidents, setRegisteredResidents] = useState([]);
    const [flatInfo, setFlatInfo] = useState();
    function fetchFlat(id) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!id)
                throw new Error(`No id provided`);
            const req = AuthorizedUser.getRequest('/flats');
            req.params.id = id;
            const [flat] = yield req.send();
            if (!flat)
                throw new Error(`Cannot find flat with id ${id}`);
            return flat;
        });
    }
    function fetchHouse(id) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!id)
                throw new Error(`No id provided`);
            const req = AuthorizedUser.getRequest('/houses');
            req.params.id = id;
            const [house] = yield req.send();
            if (!house)
                throw new Error(`Cannot find house with id ${id}`);
            return house;
        });
    }
    function fetchR2fs(flatId) {
        if (!flatId)
            throw new Error(`No flat id provided`);
        const req = AuthorizedUser.getRequest('/r2fs');
        req.params.flat = flatId;
        return req.send();
    }
    function fetchResidentsById(ids) {
        if (!Array.isArray(ids))
            throw new Error(`No residents id provided`);
        if (ids.length === 0)
            return [];
        const req = AuthorizedUser.getRequest('/residents');
        req.params.ids = ids.filter(id => !!id).join(',');
        return req.send();
    }
    function renderFlatName() {
        var _a;
        return `${(_a = flatInfo === null || flatInfo === void 0 ? void 0 : flatInfo.address) !== null && _a !== void 0 ? _a : ""} ${getFlatTypeShortName(flatInfo === null || flatInfo === void 0 ? void 0 : flatInfo.flatType)} ${flatInfo === null || flatInfo === void 0 ? void 0 : flatInfo.flatNumber}`.trim();
    }
    useEffect(() => {
        const fetchResidents = () => __awaiter(void 0, void 0, void 0, function* () {
            const flat = yield fetchFlat(flatId);
            const [house, r2fs] = yield Promise.all([
                fetchHouse(flat.house),
                fetchR2fs(flatId)
            ]);
            const residents = yield fetchResidentsById(r2fs.map(r2f => r2f.resident));
            const registeredResidentsData = r2fs
                .map((r2f) => {
                const resident = residents.find((res) => res.id === r2f.resident);
                if (resident) {
                    return {
                        id: resident.id,
                        name: resident.name,
                        surname: resident.surname || '',
                        patronymic: resident.patronymic || '',
                        phone: resident.phones[0] || '',
                        type: r2f.type,
                        status: r2f.status,
                        share: r2f.share,
                    };
                }
                return null;
            })
                .filter((resident) => resident !== null);
            setFlatInfo({ address: house.name, flatNumber: flat.number, flatType: flat.type });
            setRegisteredResidents(registeredResidentsData);
        });
        fetchResidents();
    }, [flatId]);
    const activeResidents = registeredResidents.filter((resident) => resident.status === 'A');
    const archivedResidents = registeredResidents.filter((resident) => resident.status !== 'A');
    if (activeResidents.length === 0 && archivedResidents.length === 0) {
        return _jsxs("div", Object.assign({ className: "registered-residents-section" }, { children: [_jsx("h5", { children: renderFlatName() }), _jsx("div", Object.assign({ className: "note" }, { children: "\u041D\u0435\u0442 \u0436\u0438\u0442\u0435\u043B\u0435\u0439, \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u043E\u0432\u0430\u043D\u043D\u044B\u0445 \u0432 \u043F\u043E\u043C\u0435\u0449\u0435\u043D\u0438\u0438" }))] }));
    }
    return (_jsxs("div", { children: [activeResidents.length !== 0 && (_jsxs("div", Object.assign({ className: "registered-residents-section" }, { children: [_jsx("h5", { children: `Активные жители · ${renderFlatName()}` }), activeResidents.map((rr) => (_jsx(RegisteredResident, { registeredResident: rr }, rr.id)))] }))), archivedResidents.length !== 0 && (_jsxs("div", Object.assign({ className: "registered-residents-section" }, { children: [_jsx("h5", { children: `Архивные жители · ${renderFlatName()}` }), archivedResidents.map((rr) => (_jsx(RegisteredResident, { registeredResident: rr }, rr.id)))] }))), _jsx("hr", {})] }));
};
