import { BusinesError } from "../BusinesError";
import { ErrorGroups } from "../ErrorGroups";
import { HttpErrorCodes } from "../HttpErrorCodes";
export class RoleLinkError extends BusinesError {
    constructor(message = "You don't have rights to execute this operation", code = 1, group = ErrorGroups.ROLE, httpCode = HttpErrorCodes.FORBIDDEN) {
        super(message, code, group, httpCode);
        this.code = code;
        this.group = group;
        this.httpCode = httpCode;
    }
}
