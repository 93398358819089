export class LocalStorage {
    static set(key, val) {
        localStorage.setItem(key, val);
    }
    static get(key) {
        return localStorage.getItem(key);
    }
    static clear() {
        localStorage.clear();
    }
    static get token() {
        return this.get("token");
    }
    static set token(val) {
        this.set("token", val);
    }
    static get expiration() {
        const val = this.get("expiration");
        if (!val)
            return null;
        return new Date(Date.parse(val));
    }
    static set expiration(val) {
        this.set("expiration", val ? val.toString() : null);
    }
    static get refreshToken() {
        return this.get("refreshToken");
    }
    static set refreshToken(val) {
        this.set("refreshToken", val);
    }
    static get refreshTokenExpiration() {
        const val = this.get("refreshTokenExpiration");
        if (!val)
            return null;
        return new Date(Date.parse(val));
    }
    static set refreshTokenExpiration(val) {
        this.set("refreshTokenExpiration", val ? val.toString() : null);
    }
}
