import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ObjectStatus } from '../../../types/Types';
const documentStatuses = [
    { value: ObjectStatus.ACTIVE, label: 'Активно' },
    { value: ObjectStatus.INACTIVE, label: 'Отозвано' },
];
const OpdConsentsFilter = ({ filters, onFilterChange, onSubmit, }) => {
    const handleInputChange = (e) => {
        const newFilters = Object.assign(Object.assign({}, filters), { name: e.target.value });
        onFilterChange(newFilters);
    };
    const handleSelectChange = (e) => {
        const newFilters = Object.assign(Object.assign({}, filters), { status: e.target.value });
        onFilterChange(newFilters);
    };
    return (_jsxs("div", Object.assign({ className: "flex align-center filter-bar" }, { children: [_jsx("input", { placeholder: "\u0424\u0418\u041E/\u041D\u0430\u0438\u043C\u0435\u043D\u043E\u0432\u0430\u043D\u0438\u0435 \u043E\u0440\u0433\u0430\u043D\u0438\u0437\u0430\u0446\u0438\u0438", value: filters.name || '', onChange: handleInputChange }), _jsx("label", { children: "\u0421\u0442\u0430\u0442\u0443\u0441:" }), _jsxs("select", Object.assign({ name: "status", value: filters.status || '', onChange: handleSelectChange }, { children: [_jsx("option", Object.assign({ value: "" }, { children: "\u041B\u044E\u0431\u043E\u0439" })), documentStatuses.map((status) => (_jsx("option", Object.assign({ value: status.value }, { children: status.label }), status.value)))] })), _jsx("button", Object.assign({ id: "show-more-button", type: "submit", onClick: onSubmit }, { children: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C" }))] })));
};
export default OpdConsentsFilter;
