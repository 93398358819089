export var ObjectStatus;
(function (ObjectStatus) {
    ObjectStatus["ACTIVE"] = "A";
    ObjectStatus["BLOCKED"] = "B";
    ObjectStatus["DELETED"] = "D";
    ObjectStatus["INACTIVE"] = "I";
    ObjectStatus["TEMPORARY_BLOCKED"] = "TB";
})(ObjectStatus || (ObjectStatus = {}));
export var Gender;
(function (Gender) {
    Gender["MALE"] = "M";
    Gender["FEMALE"] = "F";
})(Gender || (Gender = {}));
export var ResidentType;
(function (ResidentType) {
    ResidentType["INDIVIDUAL"] = "I";
    ResidentType["BUSINNES"] = "B";
})(ResidentType || (ResidentType = {}));
export var ResidentOwnershipType;
(function (ResidentOwnershipType) {
    ResidentOwnershipType["OWNER"] = "O";
    ResidentOwnershipType["FRIEND"] = "F";
    ResidentOwnershipType["RENT"] = "R";
    ResidentOwnershipType["REPRESENTATIVE"] = "P";
})(ResidentOwnershipType || (ResidentOwnershipType = {}));
export var FlatType;
(function (FlatType) {
    FlatType["FLAT"] = "F";
    FlatType["PARKING"] = "P";
    FlatType["COMMERCE"] = "C";
    FlatType["STORAGE"] = "S";
})(FlatType || (FlatType = {}));
export var RoleType;
(function (RoleType) {
    RoleType["SUPER_ADMIN"] = "S";
    RoleType["ADMIN"] = "A";
    RoleType["MANAGER"] = "M";
    RoleType["READ_ONLY"] = "R";
    RoleType["USER"] = "U";
})(RoleType || (RoleType = {}));
