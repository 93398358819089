import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const SelectField = ({ label, value, defaultOption, name, options, onChange, isDisabled }) => {
    const handleChange = (event) => {
        onChange(event);
    };
    const optionsArray = !Array.isArray(options) && typeof options === 'object' ? Object.values(options) : options;
    return (_jsxs("div", Object.assign({ className: "label-pair" }, { children: [_jsx("label", Object.assign({ htmlFor: name }, { children: label })), _jsxs("select", Object.assign({ id: name, name: name, value: value, onChange: handleChange, disabled: isDisabled }, { children: [_jsx("option", Object.assign({ disabled: true, value: "" }, { children: defaultOption })), optionsArray.length > 0 &&
                        optionsArray.map((option) => (_jsx("option", Object.assign({ value: option.value }, { children: option.label }), option.value)))] }))] })));
};
export default SelectField;
