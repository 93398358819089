import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet } from "react-router-dom";
import { AuthorizedUser } from "../logic/AuthorizedUser";
import { Route2Role } from "../types/RoutesToRole";
import { TopMenu } from "./TopMenu";
import ToTopBtn from "../elements/ToTopBtn";
export const Main = () => {
    var _a;
    return ((_a = AuthorizedUser.instance) === null || _a === void 0 ? void 0 : _a.isLogined) ?
        _jsxs(_Fragment, { children: [_jsx(Route2Role, {}), _jsx(TopMenu, {}), _jsx(Outlet, {}), _jsx(ToTopBtn, {})] })
        : null;
};
