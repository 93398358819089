import { BusinesError } from "../BusinesError";
import { ErrorGroups } from "../ErrorGroups";
import { HttpErrorCodes } from "../HttpErrorCodes";
export class InternalError extends BusinesError {
    constructor(message, code = 0, group = ErrorGroups.INTERANAL, httpCode = HttpErrorCodes.INTERNAL_ERROR) {
        super(message, code, group, httpCode);
        this.code = code;
        this.group = group;
        this.httpCode = httpCode;
    }
}
