import { jsx as _jsx } from "react/jsx-runtime";
import { AuthorizedUser } from "../logic/AuthorizedUser";
import { ROUTES } from "./Routes";
import { RoleType } from "./Types";
import { Navigate, useLocation } from "react-router-dom";
const ROLE_TO_EXCLUDED_PATH = {
    [RoleType.READ_ONLY]: [
        ROUTES.ADMIN,
        ROUTES.HOUSE_EDIT,
        ROUTES.LOCATION_EDIT,
        ROUTES.OPD_RDIT,
        ROUTES.RESIDENT_EDIT,
        ROUTES.SPACE_EDIT,
        ROUTES.USER_RDIT,
    ]
};
export function isPathAllowed(path) {
    var _a;
    const role = (_a = AuthorizedUser.instance) === null || _a === void 0 ? void 0 : _a.getRole();
    if (!role)
        return false;
    const excluded = ROLE_TO_EXCLUDED_PATH[role];
    if (!excluded)
        return true;
    return excluded.every(p => !path.startsWith(p));
}
export function goToLogin() {
    return _jsx(Navigate, { to: ROUTES.LOGIN, replace: true });
}
export function Route2Role() {
    const location = useLocation();
    if (isPathAllowed(location.pathname))
        return null;
    return goToLogin();
}
