export const PATH_PARTS = {
    APP: "app",
    LOGIN: "login",
    MAIN: "main",
    RECOVERY: "recovery",
    RESIDENTS: "residents",
    OBJECTS: "objects",
    REPORTS: "reports",
    ADMIN: "admin",
    SPACE: "space",
    REGISTRY: "registry",
    LOCATIONS: "locations",
    HOUSES: "houses",
    RECORDS: "records",
    USERS: "users",
    OPD: "opd",
    OPD_CON: "opd_con",
    EDIT: "edit",
    CARD: "card",
    SELF: "self_view",
};
const APP = "/" + PATH_PARTS.APP;
const LOGIN = APP + "/" + PATH_PARTS.LOGIN;
const PASSWORD_RECOVERY = LOGIN + '/' + PATH_PARTS.RECOVERY;
const MAIN = APP + "/" + PATH_PARTS.MAIN;
const RESIDENTS = MAIN + "/" + PATH_PARTS.RESIDENTS;
const RESIDENTS_REGISTER = RESIDENTS + "/" + PATH_PARTS.REGISTRY;
const OBJECTS = MAIN + "/" + PATH_PARTS.OBJECTS;
const OBJECTS_REGISTER = OBJECTS + "/" + PATH_PARTS.REGISTRY;
const LOCATIONS = OBJECTS + "/" + PATH_PARTS.LOCATIONS;
const HOUSES = OBJECTS + "/" + PATH_PARTS.HOUSES;
const REPORTS = MAIN + "/" + PATH_PARTS.REPORTS;
const SELF_VIEW = MAIN + "/" + PATH_PARTS.SELF;
const SPACE_CARD = MAIN + "/" + PATH_PARTS.SPACE + "/" + PATH_PARTS.CARD;
const SPACE_EDIT = MAIN + "/" + PATH_PARTS.SPACE + "/" + PATH_PARTS.EDIT;
const LOCATION_CARD = MAIN + "/" + PATH_PARTS.LOCATIONS + "/" + PATH_PARTS.CARD;
const LOCATION_EDIT = MAIN + "/" + PATH_PARTS.LOCATIONS + "/" + PATH_PARTS.EDIT;
const HOUSE_CARD = MAIN + "/" + PATH_PARTS.HOUSES + "/" + PATH_PARTS.CARD;
const HOUSE_EDIT = MAIN + "/" + PATH_PARTS.HOUSES + "/" + PATH_PARTS.EDIT;
const RESIDENT_CARD = MAIN + "/" + PATH_PARTS.RESIDENTS + "/" + PATH_PARTS.CARD;
const RESIDENT_EDIT = MAIN + "/" + PATH_PARTS.RESIDENTS + "/" + PATH_PARTS.EDIT;
const ADMIN = MAIN + "/" + PATH_PARTS.ADMIN;
const ADMIN_RECORDS = ADMIN + "/" + PATH_PARTS.RECORDS;
const ADMIN_USERS = ADMIN + "/" + PATH_PARTS.USERS;
const ADMIN_OPD = ADMIN + "/" + PATH_PARTS.OPD;
const ADMIN_OPD_CON = ADMIN + "/" + PATH_PARTS.OPD_CON;
const OPD_RDIT = MAIN + "/" + PATH_PARTS.OPD + "/" + PATH_PARTS.EDIT;
const USER_RDIT = MAIN + "/" + PATH_PARTS.USERS + "/" + PATH_PARTS.EDIT;
export const ROUTES = {
    APP,
    LOGIN,
    MAIN,
    PASSWORD_RECOVERY,
    RESIDENTS,
    RESIDENTS_REGISTER,
    OBJECTS,
    OBJECTS_REGISTER,
    LOCATIONS,
    HOUSES,
    REPORTS,
    ADMIN,
    SPACE_CARD,
    SPACE_EDIT,
    LOCATION_CARD,
    LOCATION_EDIT,
    HOUSE_CARD,
    HOUSE_EDIT,
    RESIDENT_CARD,
    RESIDENT_EDIT,
    ADMIN_USERS,
    ADMIN_RECORDS,
    ADMIN_OPD,
    ADMIN_OPD_CON,
    OPD_RDIT,
    USER_RDIT,
    SELF_VIEW
};
