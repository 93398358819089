import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, Outlet, useMatch, useNavigate } from "react-router-dom";
import { ROUTES } from "../../types/Routes";
import { useEffect } from "react";
import { toSelected } from "../../../../libs/utils/src/html/ClassNameUtils";
export const Objects = () => {
    const isRoot = useMatch(ROUTES.OBJECTS);
    const isOnLocations = useMatch(ROUTES.LOCATIONS);
    const isOnHouses = useMatch(ROUTES.HOUSES);
    const isOnRegistry = useMatch(ROUTES.OBJECTS_REGISTER);
    const navigate = useNavigate();
    useEffect(() => {
        isRoot && navigate(ROUTES.OBJECTS_REGISTER, { replace: true });
    }, []);
    function renderMenu(to, label, isSelected) {
        return _jsx(Link, Object.assign({ to: to, className: `objects-menu ${toSelected(isSelected)}` }, { children: label }));
    }
    return _jsx("div", Object.assign({ id: "workspace", className: "workspace-padding" }, { children: _jsxs("div", Object.assign({ id: "objects-workspace" }, { children: [_jsxs("div", Object.assign({ id: "object-menu-view", className: "no-selection" }, { children: [renderMenu(ROUTES.LOCATIONS, "ЖК", !!isOnLocations), renderMenu(ROUTES.HOUSES, "Дома", !!isOnHouses), renderMenu(ROUTES.OBJECTS_REGISTER, "Помещения", !!isOnRegistry)] })), _jsx(Outlet, {})] })) }));
};
