export function toSelected(selected) {
    return selected ? "selected" : "";
}
export function toDisabled(disabled) {
    return disabled ? "disabled" : "";
}
export function toHidden(hidden) {
    return hidden ? "hidden" : "";
}
export function toChecked(checked) {
    return checked ? "checked" : "";
}
