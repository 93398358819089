import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { processElement } from '../logic/HtmxUtils';
export const UserSelfView = () => {
    const ref = useRef(null);
    useEffect(() => {
        (ref === null || ref === void 0 ? void 0 : ref.current) && processElement(ref.current);
    }, []);
    return (_jsx("div", Object.assign({ ref: ref, "hx-trigger": "load", id: "user-card-display", "ws-send": "" }, { children: _jsx("div", { id: "main-body" }) })));
};
