var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
export class ClientRequest {
    constructor(url, method = "GET", params = {}) {
        this.url = url;
        this.method = method;
        this.params = params;
        this.config = {
            url,
            method
        };
        if (this.params)
            this.config.params = this.params;
    }
    send(data) {
        return __awaiter(this, void 0, void 0, function* () {
            if (data)
                this.config.data = data;
            const result = yield axios(this.config);
            return result.data;
        });
    }
}
