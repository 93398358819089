import { jsx as _jsx } from "react/jsx-runtime";
import { appendStringToStart } from "../../../libs/utils";
export const DateInput = ({ onChange, value, min, max }) => {
    function parseDate(str) {
        if (!str)
            return null;
        return new Date(Date.parse(str));
    }
    function toDateString(date) {
        if (!date)
            return '';
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString();
        if (month.length < 2)
            month = '0' + month;
        let day = date.getDate().toString();
        if (day.length < 2)
            day = '0' + day;
        return `${appendStringToStart(year, 4, '0')}-${month}-${day}`;
    }
    return _jsx("input", { type: "date", min: min, max: max, value: toDateString(value), onChange: e => onChange(parseDate(e.target.value)) });
};
export function fromJsonDate(str) {
    if (!str)
        return;
    return new Date(Date.parse(str));
}
