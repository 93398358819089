import { AuthError } from "./AuthError";
import { AuthErrorCodes } from "./AuthErrorCodes";
export class RefreshTokenExpired extends AuthError {
    constructor(message = `Refresh token is expired`) {
        super(message, AuthErrorCodes.REFRESH_TOKEN_EXPIRED);
    }
    static is(e) {
        return AuthError.is(e) &&
            e.code === AuthErrorCodes.REFRESH_TOKEN_EXPIRED;
    }
}
