var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import PopupController from './components/Popup/PopupController';
import { Router } from './components/Router';
import { AuthController } from './logic/AuthController';
import { WsController } from './logic/WsController';
window.onload = () => {
    const element = document.getElementById('react-root');
    if (!element)
        throw new Error('No react-root found');
    const root = createRoot(element);
    setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            WsController.initWsFromGlobal();
            yield AuthController.loadFromDb().catch(console.error);
        }
        catch (e) {
            console.error(e);
        }
        root.render(_jsxs(_Fragment, { children: [_jsx(Router, {}), _jsx(PopupController, {})] }));
    }), 1000);
};
