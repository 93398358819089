import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, useParams } from "react-router-dom";
import { HtmxRenderer } from "../../components/HtmxRenderer";
import { ROUTES } from "../../types/Routes";
export const HouseCardView = () => {
    const { id } = useParams();
    if (!id)
        return _jsx(Navigate, { to: ROUTES.HOUSES, replace: true });
    return _jsx(HtmxRenderer, { mount: "workspace", id: "open-house-card-view", inputs: [{
                name: "id",
                val: id
            }] });
};
