function getFrom(from, id) {
    const c = from.find(c => c.id === id);
    if (!c)
        throw new Error(`No structure node found with id ${id}`);
    return c;
}
function filterFrom(from, link) {
    if (!link)
        return [];
    return from.filter(e => e.parent === link);
}
function findFrom(from, link) {
    return from.find(e => e.id === link);
}
export function filterStructureNodes(cities, locations, houses, selectedCity, selectedLocation, selectedHouse, hasCity = true, hasLocation = true, hasHouse = true) {
    var _a, _b, _c;
    if (selectedHouse) {
        const house = getFrom(houses, selectedHouse);
        selectedLocation = (_a = findFrom(locations, house.parent)) === null || _a === void 0 ? void 0 : _a.id;
    }
    if (selectedLocation) {
        const location = getFrom(locations, selectedLocation);
        selectedCity = (_b = findFrom(cities, location.parent)) === null || _b === void 0 ? void 0 : _b.id;
    }
    if (!selectedCity)
        selectedCity = (_c = cities[0]) === null || _c === void 0 ? void 0 : _c.id;
    return cascadeStructure(locations, houses, selectedCity, selectedLocation, selectedHouse, hasCity, hasLocation, hasHouse);
}
export function cascadeStructure(locations, houses, selectedCity, selectedLocation, selectedHouse, hasCity = true, hasLocation = true, hasHouse = true) {
    var _a, _b;
    const filteredLocations = hasCity ?
        filterFrom(locations, selectedCity) :
        locations;
    if (!filteredLocations.some(l => l.id === selectedLocation)) {
        selectedLocation = undefined;
    }
    if (!selectedLocation) {
        selectedLocation = (_a = filteredLocations[0]) === null || _a === void 0 ? void 0 : _a.id;
    }
    const filteredHouses = hasLocation ?
        filterFrom(houses, selectedLocation) :
        houses;
    if (!filteredHouses.some(h => h.id === selectedHouse)) {
        selectedHouse = undefined;
    }
    if (!selectedHouse) {
        selectedHouse = (_b = filteredHouses[0]) === null || _b === void 0 ? void 0 : _b.id;
    }
    return {
        selectedCity,
        selectedHouse,
        selectedLocation,
        filteredLocations,
        filteredHouses
    };
}
