import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, Outlet, RouterProvider, createBrowserRouter, useLocation, useMatch, useNavigate } from "react-router-dom";
import { AuthorizedUser } from "../logic/AuthorizedUser";
import { RouterHack } from "../logic/RouterHack";
import { PATH_PARTS, ROUTES } from "../types/Routes";
import { AdminPanel } from "../workspaces/admin/AdminPanel";
import OpdConsentsView from "../workspaces/admin/OpdConsentsView/OpdConsentsView";
import { OpdEditView } from "../workspaces/admin/OpdEditView";
import { OpdView } from "../workspaces/admin/OpdView";
import { PasswordRecoveryView } from "../workspaces/admin/PasswordRecoveryView";
import { RecordsView } from "../workspaces/admin/RecordsView";
import { UserEditView } from "../workspaces/admin/UserEditView";
import { UsersView } from "../workspaces/admin/UsersView";
import { HouseCardView } from "../workspaces/objects/HouseCardView";
import { HouseEditView } from "../workspaces/objects/HouseEditView";
import { LocationCardView } from "../workspaces/objects/LocationCardView";
import { LocationEditView } from "../workspaces/objects/LocationEditView";
import { ObjectRegistry } from "../workspaces/objects/ObjectRegistry";
import { Objects } from "../workspaces/objects/Objects";
import { ObjectsHouses } from "../workspaces/objects/ObjectsHouses";
import { ObjectsLocations } from "../workspaces/objects/ObjectsLocations";
import { SpaceCardView } from "../workspaces/objects/SpaceCardView";
import { SpaceEditView } from "../workspaces/objects/SpaceEditView";
import { ReportsConstructor } from "../workspaces/ReportsConstructor";
import { ResidentCardView } from "../workspaces/residents/ResidentCardView";
import { ResidentRegistry } from "../workspaces/residents/ResidentRegistry";
import { ResidentView } from "./EditResidentPage/ResidentView";
import { LoginContext } from "./LoginContext";
import { LoginPage } from "./LoginPage";
import { Main } from "./Main";
import { UserSelfView } from "./UserSelfView";
import { LockProcessor } from "../logic/LockProcessor";
const AppRouter = () => {
    var _a;
    const [isLogined, setIsLogined] = useState(!!((_a = AuthorizedUser.instance) === null || _a === void 0 ? void 0 : _a.isLogined));
    const navigate = useNavigate();
    const isInLoginPath = useMatch(ROUTES.LOGIN);
    const isInMainApp = useMatch({
        path: ROUTES.MAIN,
        end: false
    });
    const location = useLocation();
    const locationRef = useRef('');
    const prevPath = isInMainApp ? location.pathname + location.search : ROUTES.RESIDENTS_REGISTER;
    function handleLoginPaths() {
        if (!isLogined)
            return navigate(ROUTES.LOGIN);
        if (isInLoginPath || !isInMainApp)
            return navigate(prevPath);
    }
    useEffect(() => {
        handleLoginPaths();
    }, [isLogined]);
    useEffect(() => {
        handleLoginPaths();
    }, []);
    useEffect(() => {
        LockProcessor.unlockIfLocked(locationRef.current);
        locationRef.current = location.pathname;
    }, [location]);
    return _jsx(LoginContext.Provider, Object.assign({ value: {
            onLogin: () => setIsLogined(true),
            onLogout: () => {
                var _a;
                (_a = AuthorizedUser.instance) === null || _a === void 0 ? void 0 : _a.logout();
                setIsLogined(false);
            }
        } }, { children: _jsx(Outlet, {}) }));
};
const router = createBrowserRouter([
    {
        path: PATH_PARTS.APP,
        element: _jsx(AppRouter, {}),
        children: [
            {
                path: PATH_PARTS.LOGIN,
                element: _jsx(LoginPage, {}),
                children: [
                    {
                        path: PATH_PARTS.RECOVERY,
                        element: _jsx(PasswordRecoveryView, {})
                    },
                ]
            },
            {
                path: PATH_PARTS.MAIN,
                element: _jsx(Main, {}),
                children: [
                    {
                        path: PATH_PARTS.OBJECTS,
                        element: _jsx(Objects, {}),
                        children: [
                            {
                                path: PATH_PARTS.REGISTRY,
                                element: _jsx(ObjectRegistry, {})
                            },
                            {
                                path: PATH_PARTS.LOCATIONS,
                                element: _jsx(ObjectsLocations, {})
                            },
                            {
                                path: PATH_PARTS.HOUSES,
                                element: _jsx(ObjectsHouses, {})
                            },
                            {
                                path: '*',
                                element: _jsx(Navigate, { to: PATH_PARTS.REGISTRY, replace: true })
                            }
                        ]
                    },
                    {
                        path: PATH_PARTS.REPORTS,
                        element: _jsx(ReportsConstructor, {})
                    },
                    {
                        path: PATH_PARTS.SELF,
                        element: _jsx(UserSelfView, {})
                    },
                    {
                        path: PATH_PARTS.SPACE,
                        element: _jsx(Outlet, {}),
                        children: [
                            {
                                path: PATH_PARTS.EDIT + "/:id",
                                element: _jsx(SpaceEditView, {})
                            },
                            {
                                path: PATH_PARTS.CARD + "/:id",
                                element: _jsx(SpaceCardView, {})
                            },
                            {
                                path: '*',
                                element: _jsx(Navigate, { to: ROUTES.OBJECTS_REGISTER, replace: true })
                            }
                        ]
                    },
                    {
                        path: PATH_PARTS.LOCATIONS,
                        element: _jsx(Outlet, {}),
                        children: [
                            {
                                path: PATH_PARTS.EDIT + "/:id",
                                element: _jsx(LocationEditView, {})
                            },
                            {
                                path: PATH_PARTS.CARD + "/:id",
                                element: _jsx(LocationCardView, {})
                            },
                            {
                                path: '*',
                                element: _jsx(Navigate, { to: ROUTES.LOCATIONS, replace: true })
                            }
                        ]
                    },
                    {
                        path: PATH_PARTS.HOUSES,
                        element: _jsx(Outlet, {}),
                        children: [
                            {
                                path: PATH_PARTS.EDIT + "/:id",
                                element: _jsx(HouseEditView, {})
                            },
                            {
                                path: PATH_PARTS.CARD + "/:id",
                                element: _jsx(HouseCardView, {})
                            },
                            {
                                path: '*',
                                element: _jsx(Navigate, { to: ROUTES.HOUSES, replace: true })
                            }
                        ]
                    },
                    {
                        path: PATH_PARTS.RESIDENTS,
                        element: _jsx(Outlet, {}),
                        children: [
                            {
                                path: PATH_PARTS.REGISTRY,
                                element: _jsx(ResidentRegistry, {})
                            },
                            {
                                path: PATH_PARTS.EDIT + "/:id",
                                element: _jsx(ResidentView, {})
                            },
                            {
                                path: PATH_PARTS.CARD + "/:id",
                                element: _jsx(ResidentCardView, {})
                            },
                            {
                                path: '*',
                                element: _jsx(Navigate, { to: ROUTES.HOUSES, replace: true })
                            }
                        ]
                    },
                    {
                        path: PATH_PARTS.ADMIN,
                        element: _jsx(AdminPanel, {}),
                        children: [
                            {
                                path: PATH_PARTS.USERS,
                                element: _jsx(UsersView, {})
                            },
                            {
                                path: PATH_PARTS.RECORDS,
                                element: _jsx(RecordsView, {})
                            },
                            {
                                path: PATH_PARTS.OPD,
                                element: _jsx(OpdView, {})
                            },
                            {
                                path: PATH_PARTS.OPD_CON,
                                element: _jsx(OpdConsentsView, {})
                            },
                            {
                                path: '*',
                                element: _jsx(Navigate, { to: ROUTES.ADMIN_RECORDS, replace: true })
                            }
                        ]
                    },
                    {
                        path: PATH_PARTS.OPD,
                        element: _jsx(Outlet, {}),
                        children: [
                            {
                                path: PATH_PARTS.EDIT + "/:id",
                                element: _jsx(OpdEditView, {})
                            },
                            {
                                path: '*',
                                element: _jsx(Navigate, { to: ROUTES.ADMIN_OPD, replace: true })
                            }
                        ]
                    },
                    {
                        path: PATH_PARTS.USERS,
                        element: _jsx(Outlet, {}),
                        children: [
                            {
                                path: PATH_PARTS.EDIT + "/:id",
                                element: _jsx(UserEditView, {})
                            },
                            {
                                path: '*',
                                element: _jsx(Navigate, { to: ROUTES.ADMIN_USERS, replace: true })
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: "*",
        element: _jsx(Navigate, { to: ROUTES.RESIDENTS_REGISTER, replace: true })
    }
]);
function getRouter() {
    const r = _jsx(RouterProvider, { router: router });
    RouterHack.initNavigate(r.props.router.navigate);
    return r;
}
export const Router = () => {
    return _jsx(React.StrictMode, { children: getRouter() });
};
