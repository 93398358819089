import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link, Outlet, useMatch, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ROUTES } from '../../types/Routes';
import { toSelected } from '../../../../libs/utils/src/html/ClassNameUtils';
export const AdminPanel = () => {
    const isRoot = useMatch(ROUTES.ADMIN);
    const isOnUsers = useMatch(ROUTES.ADMIN_USERS);
    const isOnRecords = useMatch(ROUTES.ADMIN_RECORDS);
    const isOnOpd = useMatch(ROUTES.ADMIN_OPD);
    const isOnOpdCon = useMatch(ROUTES.ADMIN_OPD_CON);
    const navigate = useNavigate();
    useEffect(() => {
        isRoot && navigate(ROUTES.ADMIN_USERS, { replace: true });
    }, []);
    function renderMenu(to, label, isSelected) {
        return (_jsx(Link, Object.assign({ to: to, className: `objects-menu ${toSelected(isSelected)}` }, { children: label })));
    }
    return (_jsx("div", Object.assign({ id: "workspace", className: "workspace-padding" }, { children: _jsxs("div", Object.assign({ id: "objects-workspace" }, { children: [_jsxs("div", Object.assign({ id: "object-menu-view", className: "no-selection" }, { children: [renderMenu(ROUTES.ADMIN_USERS, 'Пользователи', !!isOnUsers), renderMenu(ROUTES.ADMIN_RECORDS, 'Справочники', !!isOnRecords), renderMenu(ROUTES.ADMIN_OPD, 'ОПД', !!isOnOpd), renderMenu(ROUTES.ADMIN_OPD_CON, 'Реестр согласий на ОПД', !!isOnOpdCon)] })), _jsx(Outlet, {})] })) })));
};
