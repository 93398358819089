import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, useParams } from "react-router-dom";
import { HtmxRenderer } from "../../components/HtmxRenderer";
import { ROUTES } from "../../types/Routes";
export const UserEditView = () => {
    const { id } = useParams();
    let inputs;
    if (!id)
        return _jsx(Navigate, { to: ROUTES.ADMIN_USERS, replace: true });
    if (id !== "new") {
        inputs = [{
                name: "id",
                val: id
            }];
    }
    return _jsx(HtmxRenderer, { mount: "workspace", id: "open-edit-user-view", inputs: inputs });
};
