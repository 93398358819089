import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { getOwnershipDisplayName } from '../../../../libs/utils';
import { ROUTES } from '../../types/Routes';
const RegisteredResident = ({ registeredResident }) => {
    const ownershipLabel = getOwnershipDisplayName(registeredResident.type);
    return (_jsxs("div", Object.assign({ className: `registered-resident ${registeredResident.status === 'A' ? '' : 'archived'}` }, { children: [_jsxs("div", Object.assign({ className: "owner-info-container" }, { children: [_jsx("h5", { children: `${registeredResident.surname} ${registeredResident.name} ${registeredResident.patronymic}` }), _jsxs("div", Object.assign({ className: "owner-info" }, { children: [ownershipLabel, registeredResident.status === 'A' &&
                                ` ${registeredResident.share} | ${registeredResident.phone}`] }))] })), _jsx(Link, Object.assign({ to: `${ROUTES.RESIDENT_EDIT}/${registeredResident.id}` }, { children: _jsx("img", { className: "edit-registered-resident", src: "/public/img/edit2.svg", alt: "edit" }) }))] }), registeredResident.id));
};
export default RegisteredResident;
