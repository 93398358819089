import { BusinesError } from "../BusinesError";
import { ErrorGroups } from "../ErrorGroups";
import { HttpErrorCodes } from "../HttpErrorCodes";
export class ResidentCardError extends BusinesError {
    constructor(message, code, data) {
        super(message, code, ErrorGroups.RESIDENT_CARD_GROUP, HttpErrorCodes.BAD_REQUEST, data);
    }
    static is(e) {
        return BusinesError.is(e) &&
            e.group === ErrorGroups.RESIDENT_CARD_GROUP;
    }
}
