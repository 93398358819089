import { AuthError } from "./AuthError";
import { AuthErrorCodes } from "./AuthErrorCodes";
export class AccessBlocked extends AuthError {
    constructor(message = "Access is blocked") {
        super(message, AuthErrorCodes.ACCESS_BLOCKED);
    }
    static is(e) {
        return AuthError.is(e) &&
            e.code === AuthErrorCodes.ACCESS_BLOCKED;
    }
}
