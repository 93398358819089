var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { MapObjectKnownNames } from '../../../libs/types';
import { AuthorizedUser } from '../logic/AuthorizedUser';
export const MapObjectSelect = ({ name, value, field, onChange }) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        const loadMapObject = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const req = AuthorizedUser.getRequest('/map-object', 'get');
            req.params.name = field;
            const values = (yield req.send());
            if (!Array.isArray(values))
                throw new Error(`Не найден динамический справочник ${MapObjectKnownNames.PROPERTY_DOCUMENT_TYPE}`);
            setOptions(values);
            if (!value || !values.find(v => v.id === value)) {
                onChange((_a = values[0]) === null || _a === void 0 ? void 0 : _a.id);
            }
        });
        loadMapObject();
    }, []);
    return (_jsx("select", Object.assign({ name: name, value: value, onChange: e => onChange(e.target.value) }, { children: options.map((option) => (_jsx("option", Object.assign({ value: option.id }, { children: option.value }), option.id))) })));
};
