import { FlatType, Gender, ObjectStatus, ResidentOwnershipType, RoleType } from "../../../types";
export function getLocationName(locationLike) {
    return locationLike.name || locationLike.address || locationLike.shortName || "";
}
export function getLocationShortName(locationLike) {
    return locationLike.shortName || locationLike.name || locationLike.address || "";
}
export function getLocationAddress(locationLike) {
    return locationLike.address || locationLike.name || locationLike.shortName || "";
}
export function getNameFromPersonalData(data) {
    var _a, _b, _c;
    return `${(_a = data.surname) !== null && _a !== void 0 ? _a : ''} ${(_b = data.name) !== null && _b !== void 0 ? _b : ''} ${(_c = data.patronymic) !== null && _c !== void 0 ? _c : ''}`.trim();
}
export function getFlatTypeShortName(type) {
    switch (type) {
        case FlatType.FLAT:
            return 'кв.';
        case FlatType.STORAGE:
            return 'кл.';
        case FlatType.COMMERCE:
            return 'ком.';
        case FlatType.PARKING:
            return 'маш.';
    }
}
export function getRoleDisplayName(role) {
    switch (role) {
        case RoleType.SUPER_ADMIN:
            return 'Супер Админ';
        case RoleType.ADMIN:
            return 'Администратор';
        case RoleType.MANAGER:
            return 'Менеджер';
        case RoleType.READ_ONLY:
            return 'Только чтение';
        default:
            return role;
    }
}
export function getStatusDisplayName(status) {
    switch (status) {
        case ObjectStatus.ACTIVE:
            return 'Активный';
        case ObjectStatus.BLOCKED:
            return 'Заблокирован';
        case ObjectStatus.INACTIVE:
            return 'Неактивен';
        case ObjectStatus.DELETED:
            return 'Архивирован';
        case ObjectStatus.TEMPORARY_BLOCKED:
            return 'Временно заблокирован';
    }
}
export function getFlatTypeDisplayName(type) {
    switch (type) {
        case FlatType.FLAT:
            return 'Квартира';
        case FlatType.STORAGE:
            return 'Кладовая';
        case FlatType.COMMERCE:
            return 'Коммерческое';
        case FlatType.PARKING:
            return 'Машиноместо';
    }
}
export function getGenderDisplayName(gender) {
    switch (gender) {
        case Gender.MALE:
            return 'Мужской';
        case Gender.FEMALE:
            return 'Женский';
    }
}
export function getOwnershipDisplayName(type) {
    switch (type) {
        case ResidentOwnershipType.OWNER:
            return 'Собственник';
        case ResidentOwnershipType.FRIEND:
            return 'Житель';
        case ResidentOwnershipType.RENT:
            return 'Арендатор';
        case ResidentOwnershipType.REPRESENTATIVE:
            return 'Представитель';
        default:
            return "";
    }
}
export function getValueBetweenTwoStrings(str, s1, s2) {
    return str.substring(str.indexOf(s1) + 1, str.lastIndexOf(s2));
}
export function bool2YesNo(val) {
    return val ? "Да" : "Нет";
}
export function parseNumber(val) {
    if (typeof val === "number")
        return val;
    val = val.replace(',', '.');
    return parseFloat(val);
}
