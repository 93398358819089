import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
export const PhoneInput = ({ value, onChange, maxLength = 11, placeholder = "7 XXX XXX XXXX" }) => {
    const [inputValue, setInputValue] = useState(value);
    const [error, setError] = useState(null);
    useEffect(() => {
        setInputValue(value);
    }, [value]);
    const handleInputChange = (e) => {
        const newValue = e.target.value.replace(/[^\d]/g, "");
        setInputValue(newValue);
        if (newValue === "" || newValue.length === maxLength) {
            onChange(newValue);
            setError(null);
        }
        else {
            setError(`Номер должен содержать ${maxLength} цифр или быть пустым`);
        }
    };
    return (_jsxs("div", { children: [_jsx("input", { type: "text", value: inputValue, maxLength: maxLength, onChange: handleInputChange, placeholder: placeholder }), error && _jsx("span", Object.assign({ style: { color: 'red', display: 'block', marginTop: '4px' } }, { children: error }))] }));
};
