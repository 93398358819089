import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, useRef, Fragment } from 'react';
const POPUP_EXPIRATION_TIMEOUT = 10 * 1000;
function getMessageFromApiError({ code, group, message, }) {
    console.log(group, code, message);
    switch (group) {
        case 'auth':
            switch (code) {
                case 0:
                    return 'Неверные данные авторизации';
                // code 1 will be processed under the hood
                case 2:
                    return 'Время жизни авторизации истекло, авторизируйтесь еще раз';
            }
    }
    if (message)
        return message;
    return 'Неизвестная ошибка';
}
function getErrorMessage(e) {
    var _a, _b;
    if (!e)
        return 'Неизвестная ошибка';
    if (typeof e === 'string')
        return e;
    const errorAns = (_a = e.response) === null || _a === void 0 ? void 0 : _a.data;
    if (errorAns) {
        if (typeof errorAns.code === 'number' && typeof errorAns.group === 'string')
            return getMessageFromApiError(errorAns);
    }
    return (_b = e.message) !== null && _b !== void 0 ? _b : 'Неизвестная ошибка';
}
let addPopup;
export function errorPopup(e) {
    console.error(e);
    addPopup(_jsx("div", Object.assign({ className: "popup error" }, { children: getErrorMessage(e) })));
}
export function infoPopup(info) {
    addPopup(_jsx("div", Object.assign({ className: "popup info" }, { children: info })));
}
const PopupController = () => {
    const [popups, setPopups] = useState([]);
    const mountedRef = useRef(false);
    useEffect(() => {
        addPopup = (popup) => {
            setPopups((currentPopups) => [...currentPopups, popup]);
            expirePopup(popup);
        };
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);
    function expirePopup(popup) {
        setTimeout(() => {
            setPopups((currentPopups) => currentPopups.filter((p) => p !== popup));
        }, POPUP_EXPIRATION_TIMEOUT);
    }
    return _jsx("div", Object.assign({ id: "popup-container" }, { children: popups.map((p, i) => _jsx(Fragment, { children: p }, i)) }));
};
export default PopupController;
