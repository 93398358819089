const transformPhoneNumber = (phoneNumber) => {
    if (typeof phoneNumber !== 'string') {
        return '';
    }
    // TODO use regex
    return phoneNumber
        .split('')
        .filter((i) => !['+', '(', ')', '-', ' '].includes(i))
        .join('');
};
export { transformPhoneNumber };
export function appendStringToStart(str, length, fill) {
    str = str.toString();
    for (let i = str.length; i < length; i++) {
        str = fill + str;
    }
    return str;
}
export function appendStringTEnd(str, length, fill) {
    str = str.toString();
    for (let i = str.length; i < length; i++) {
        str += fill;
    }
    return str;
}
