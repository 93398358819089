import { jsx as _jsx } from "react/jsx-runtime";
const TABLE_HEADER = [
    { label: 'Дата подписи', column: 'created_at' },
    { label: 'Пользователь', column: 'user' },
    { label: 'Версия', column: 'version' },
    { label: 'Дата', column: 'versionDate' },
    { label: 'Устройство', column: 'device' },
    { label: 'Статус', column: 'status' },
    { label: 'Актуальность', column: 'relevant' },
    { label: 'Дата отзыва', column: 'updated_at' },
];
const OpdConsentsTableHeader = ({ onSortChange, sortParams, }) => {
    const handleSort = (column) => {
        onSortChange(column);
    };
    const getClassNames = (column) => {
        if (sortParams.column === column) {
            return `active ${sortParams.direction === '-1' ? 'ascending' : 'descending'}`;
        }
        return '';
    };
    return (_jsx("thead", { children: _jsx("tr", { children: TABLE_HEADER.map((field) => (_jsx("th", Object.assign({ onClick: () => handleSort(field.column), className: getClassNames(field.column) }, { children: field.label }), field.column))) }) }));
};
export default OpdConsentsTableHeader;
