export var ResidentFieldKeys;
(function (ResidentFieldKeys) {
    ResidentFieldKeys["NAME"] = "name";
    ResidentFieldKeys["SURNAME"] = "surname";
    ResidentFieldKeys["NUMBER"] = "number";
    ResidentFieldKeys["OWNERSHIP"] = "ownership";
    ResidentFieldKeys["HOUSE"] = "house";
    ResidentFieldKeys["SPACE_TYPE"] = "space-type";
    ResidentFieldKeys["SHARE"] = "share";
    ResidentFieldKeys["BIRTH_DATE"] = "birth";
    ResidentFieldKeys["GENDER"] = "gender";
})(ResidentFieldKeys || (ResidentFieldKeys = {}));
