import { InternalError } from "../../../errors";
import { FlatType, Gender, ResidentOwnershipType } from "../../../types";
export function getGenderFromLegendaImport(str) {
    switch (str) {
        case 'женский':
            return Gender.FEMALE;
        case 'мужской':
            return Gender.MALE;
        default:
            throw new InternalError(`Unknown gender ${str}`);
    }
}
export function getFlatTypeFromLgendaImport(str) {
    switch (str) {
        case 'Квартира':
            return FlatType.FLAT;
        case 'Коммерческое помещение':
            return FlatType.COMMERCE;
        case 'Машиноместо':
            return FlatType.PARKING;
        default:
            throw new InternalError(`Unknown flat type ${str}`);
    }
}
export function getOwnershipFromLegendaImport(str) {
    switch (str) {
        case 'Собственник':
            return ResidentOwnershipType.OWNER;
        case 'Житель':
            return ResidentOwnershipType.FRIEND;
        case 'Представитель собственника':
            return ResidentOwnershipType.REPRESENTATIVE;
        case 'Арендатор':
            return ResidentOwnershipType.RENT;
        default:
            throw new InternalError(`Unknown ownership type ${str}`);
    }
}
