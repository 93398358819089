import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { processElement } from "../logic/HtmxUtils";
export const HtmxRenderer = (props) => {
    const ref = useRef(null);
    useEffect(() => {
        (ref === null || ref === void 0 ? void 0 : ref.current) && processElement(ref.current);
    }, []);
    function addInputs() {
        if (!Array.isArray(props.inputs))
            return null;
        return props.inputs.map((obj, i) => _jsx("input", { name: obj.name, value: obj.val, hidden: true, readOnly: true }, i));
    }
    return _jsxs("form", Object.assign({ "hx-trigger": "load", id: props.id, "ws-send": "", ref: ref }, { children: [addInputs(), _jsx("div", { id: props.mount })] }));
};
