import { AuthError } from "./AuthError";
import { AuthErrorCodes } from "./AuthErrorCodes";
export class UnauthorizedError extends AuthError {
    constructor(message = "Unauthorized") {
        super(message, AuthErrorCodes.UNAUTHORIZED);
    }
    static is(e) {
        return AuthError.is(e) &&
            e.code === AuthErrorCodes.UNAUTHORIZED;
    }
}
