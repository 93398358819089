/**
 * Proccesses DOM element in htmx, enabling HTMX tags and attributes
 * @param element DOM element from react ref
 */
export function processHtmx(element) {
    // @ts-ignore
    htmx.process(element);
}
/**
 * Proccesses DOM element in hyperscript, enabling Hyperscript tags and attributes
 * @param element DOM element from react ref
 */
export function processHyperscript(element) {
    // @ts-ignore
    _hyperscript.processNode(element);
}
export function triggerEvent(id, event) {
    // @ts-ignore
    htmx.trigger(`#${id}`, event);
}
/**
 * Proccesses DOM element.
 * Enables HTMX and Hyperscript tags and attributes.
 * @param element DOM element from react ref
 */
export function processElement(element) {
    processHtmx(element);
    processHyperscript(element);
}
/**
 * Get current authorization token.
 * Is currently set in index.html template file.
 * @returns Auth token
 */
export function getToken() {
    // @ts-ignore
    if (typeof TOKEN == 'undefined')
        return undefined;
    // @ts-ignore
    return TOKEN;
}
/**
 * Get current authorization token expiration.
 * Is currently set in index.html template file.
 * @returns Auth token expiration
 */
export function getTokenExpiration() {
    // @ts-ignore
    if (typeof EXPIRATION == 'undefined')
        return undefined;
    // @ts-ignore
    return EXPIRATION;
}
/**
 * Get current refresh token.
 * Is currently set in index.html template file.
 * @returns Refresh token
 */
export function getRefreshToken() {
    // @ts-ignore
    if (typeof REFRESH_TOKEN == 'undefined')
        return undefined;
    // @ts-ignore
    return REFRESH_TOKEN;
}
/**
 * Get current refresh token expiration.
 * Is currently set in index.html template file.
 * @returns Refresh token expiration
 */
export function getRefreshTokenExpiration() {
    // @ts-ignore
    if (typeof REFRESH_EXPIRATION == 'undefined')
        return undefined;
    // @ts-ignore
    return REFRESH_EXPIRATION;
}
/**
 * Sets token for file upload requests
 * @deprecated
 * @param token
 */
export function setToken(token) {
    // @ts-ignore
    SET_TOKEN(token);
}
