export class RouterHack {
    static initNavigate(func) {
        RouterHack._navigate = func;
        //@ts-ignore Global var
        window.navigate = RouterHack._navigate;
    }
    static checkOrThrow() {
        if (typeof RouterHack._navigate !== "function") {
            throw new Error(`Bad type of navigate - ${typeof RouterHack._navigate}`);
        }
    }
    static navigate(to, opt) {
        this.checkOrThrow();
        RouterHack._navigate(to, opt);
    }
    static back() {
        this.navigate(-1);
    }
}
