export var ErrorGroups;
(function (ErrorGroups) {
    ErrorGroups["UNKNOWN"] = "unkown";
    ErrorGroups["AUTH"] = "auth";
    ErrorGroups["ACCESS"] = "access";
    ErrorGroups["INTERANAL"] = "internal";
    ErrorGroups["DATABASE"] = "database";
    ErrorGroups["BAD_REQUEST"] = "bad_request";
    ErrorGroups["ROLE"] = "role";
    ErrorGroups["RESIDENT_CARD_GROUP"] = "resident_card";
    ErrorGroups["DPA"] = "dpa";
    ErrorGroups["LOCK"] = "lock";
    ErrorGroups["FILES"] = "file";
})(ErrorGroups || (ErrorGroups = {}));
