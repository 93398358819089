import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { processElement } from "../../logic/HtmxUtils";
export const ObjectsLocations = () => {
    const ref = useRef(null);
    useEffect(() => {
        (ref === null || ref === void 0 ? void 0 : ref.current) && processElement(ref.current);
    }, []);
    return _jsx("div", Object.assign({ "hx-trigger": "load", id: "objects-object-view", "ws-send": "", ref: ref }, { children: _jsx("div", { id: "object-view-content" }) }));
};
