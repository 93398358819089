import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { getNameFromPersonalData, getRoleDisplayName } from '../../../libs/utils/src/strings/namingUtils';
import { AuthorizedUser } from '../logic/AuthorizedUser';
import { LoginContext } from './LoginContext';
import { Link } from 'react-router-dom';
import { ROUTES } from '../types/Routes';
export const UserCard = () => {
    var _a;
    const data = (_a = AuthorizedUser.instance) === null || _a === void 0 ? void 0 : _a.getData();
    if (!data)
        return null;
    const { login, role, info } = data;
    const { onLogout } = useContext(LoginContext);
    const name = info ? getNameFromPersonalData(info) : login;
    return (_jsxs("div", Object.assign({ className: "flex" }, { children: [_jsx(Link, Object.assign({ to: ROUTES.SELF_VIEW }, { children: _jsxs("div", Object.assign({ id: "user-card-display" }, { children: [_jsx("div", Object.assign({ className: "highlighted" }, { children: name })), _jsx("p", Object.assign({ className: "greyed" }, { children: getRoleDisplayName(role) }))] })) })), _jsx("div", { className: "vertical-divider" }), _jsx("img", { id: "exit-app", src: "/public/img/exit-button.svg", onClick: onLogout })] })));
};
