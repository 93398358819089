;
export const LockDocumentApi = {
    method: 'post',
    path: '/document/lock'
};
export const UnlockDocumentApi = {
    method: 'post',
    path: '/document/unlock'
};
export const GetLockedDocumentsApi = {
    method: 'get',
    path: '/document/locked'
};
