export var EAuthProvider;
(function (EAuthProvider) {
    EAuthProvider["OBIT"] = "obit";
})(EAuthProvider || (EAuthProvider = {}));
export var EAuthType;
(function (EAuthType) {
    EAuthType["EMAIL"] = "email";
    EAuthType["CALL"] = "call";
    EAuthType["SMS"] = "sms";
})(EAuthType || (EAuthType = {}));
