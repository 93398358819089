var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { TokenExpiredError } from '../../../libs/errors';
import { AuthController } from './AuthController';
import { ClientRequest } from './ClientRequest';
import { AuthorizedUser } from './AuthorizedUser';
import { X_TOKEN_HEADER } from '../../../libs/lightApi';
export class AuthorizedRequest extends ClientRequest {
    send(data) {
        const _super = Object.create(null, {
            send: { get: () => super.send }
        });
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            if (!AuthorizedUser.instance) {
                throw new Error("No user instantiated for request");
            }
            const token = AuthorizedUser.instance.token;
            if (!token) {
                throw new Error("No token found for request");
            }
            this.config.headers = { [X_TOKEN_HEADER]: token };
            try {
                return yield _super.send.call(this, data);
            }
            catch (e) {
                const response = (_a = e === null || e === void 0 ? void 0 : e.response) === null || _a === void 0 ? void 0 : _a.data;
                if (TokenExpiredError.is(response)) {
                    console.log("Auth token is expired, refreshing");
                    yield AuthController.refreshToken();
                    return this.send(data);
                }
                throw e;
            }
        });
    }
}
