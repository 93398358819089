import { DpaType } from "../common/DpaTypes";
export const DpaNames = {
    [DpaType.CONFIDENTIALITY_POLICY]: "Политика конфиденциальности",
    [DpaType.PERSONAL_DATA]: "Политика обработки персональных данных",
    [DpaType.USER_AGGREMENT]: "Пользовательское соглашение"
};
export const getDpaName = (dpa) => {
    const str = DpaNames[dpa];
    if (!str)
        return "-";
    return str;
};
export const DpaT2N = Object.values(DpaType)
    .map(type => ({
    type,
    name: DpaNames[type]
}));
