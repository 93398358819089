import { ErrorGroups } from "./ErrorGroups";
import { HttpErrorCodes } from "./HttpErrorCodes";
export class BusinesError extends Error {
    constructor(message, code = 0, group = ErrorGroups.UNKNOWN, httpCode = HttpErrorCodes.INTERNAL_ERROR, data) {
        super(message);
        this.code = code;
        this.group = group;
        this.httpCode = httpCode;
        this.data = data;
    }
    getErrorResponse() {
        return {
            message: this.message,
            code: this.code,
            group: this.group,
            data: this.data
        };
    }
    static is(obj) {
        return typeof obj === "object" &&
            typeof obj.group === "string" &&
            typeof obj.code === "number";
    }
}
