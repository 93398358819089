import { BusinesError } from "../BusinesError";
import { ErrorGroups } from "../ErrorGroups";
import { HttpErrorCodes } from "../HttpErrorCodes";
import { FileRequestErrorCodes } from "./FileRequestErrorCodes";
export class FileRequestError extends BusinesError {
    constructor(message, code = FileRequestErrorCodes.UNKNOWN) {
        super(message, code, ErrorGroups.FILES, HttpErrorCodes.NOT_FOUND);
        this.code = code;
    }
}
