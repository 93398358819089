var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { MapObjectKnownNames } from '../../../libs/types';
import { filterStructureNodes } from '../../../libs/utils';
import { AuthorizedUser } from '../logic/AuthorizedUser';
import SelectField from './Select';
const ComboSpaceSelect = ({ selectedCity, selectedLocation, selectedHouse, isDisabled = false, onChange, }) => {
    const [cities, setCities] = useState([]);
    const [locations, setLocations] = useState([]);
    const [houses, setHouses] = useState([]);
    const [cityState, setSelectedCity] = useState('');
    const [locationState, setSelectedLocation] = useState('');
    const [houseState, setSelectedHouse] = useState('');
    const [allCities, setAllCities] = useState([]);
    const [allLocations, setAllLocations] = useState([]);
    const [allHouses, setAllHouses] = useState([]);
    function loadData() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield Promise.all([
                loadCities(),
                loadLocations(),
                loadHouses()
            ]);
            setAllCities(result[0]);
            setAllLocations(result[1]);
            setAllHouses(result[2]);
            return {
                cities: result[0],
                locations: result[1],
                houses: result[2]
            };
        });
    }
    function loadCities() {
        return __awaiter(this, void 0, void 0, function* () {
            const req = AuthorizedUser.getRequest('/map-object', 'get');
            req.params.name = MapObjectKnownNames.CITIES;
            const result = yield req.send();
            return result.map(r => ({ id: r.id, value: r.id, label: r.value, parent: '' }));
        });
    }
    function loadLocations() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield AuthorizedUser.getRequest('/locations', 'get').send();
            return result.map(r => ({ id: r.id, value: r.id, label: r.name, parent: r.city }));
        });
    }
    function loadHouses() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield AuthorizedUser.getRequest('/houses', 'get').send();
            return result.map(r => ({ id: r.id, value: r.id, label: r.name, parent: r.location }));
        });
    }
    function filterFrom(from, link) {
        if (!link)
            return [];
        return from.filter(e => e.parent === link);
    }
    useEffect(() => {
        loadData()
            .then(({ cities, houses, locations }) => {
            const res = filterStructureNodes(cities, locations, houses, selectedCity, selectedLocation, selectedHouse);
            setSelectedCity(res.selectedCity);
            setSelectedLocation(res.selectedLocation);
            setSelectedHouse(res.selectedHouse);
            setCities(cities);
            setLocations(res.filteredLocations);
            setHouses(res.filteredHouses);
            onChange(res.selectedCity, res.selectedLocation, res.selectedHouse);
        })
            .catch(console.error);
    }, []);
    function handlCityChange(e) {
        var _a, _b;
        selectedCity = e.target.value;
        const filteredLocations = filterFrom(allLocations, selectedCity);
        selectedLocation = ((_a = filteredLocations[0]) === null || _a === void 0 ? void 0 : _a.id) || '';
        const filteredHouses = filterFrom(allHouses, selectedLocation);
        selectedHouse = ((_b = filteredHouses[0]) === null || _b === void 0 ? void 0 : _b.id) || '';
        setSelectedCity(selectedCity);
        setSelectedLocation(selectedLocation);
        setSelectedHouse(selectedHouse);
        setLocations(filteredLocations);
        setHouses(filteredHouses);
        onChange(selectedCity, selectedLocation, selectedHouse);
    }
    function handlLocationChange(e) {
        var _a;
        selectedLocation = e.target.value;
        const filteredHouses = filterFrom(allHouses, selectedLocation);
        selectedHouse = ((_a = filteredHouses[0]) === null || _a === void 0 ? void 0 : _a.id) || '';
        setSelectedLocation(selectedLocation);
        setSelectedHouse(selectedHouse);
        setHouses(filteredHouses);
        onChange(selectedCity, selectedLocation, selectedHouse);
    }
    function handlHouseChange(e) {
        selectedHouse = e.target.value;
        setSelectedHouse(selectedHouse);
        onChange(selectedCity, selectedLocation, selectedHouse);
    }
    return (_jsxs("div", Object.assign({ className: "flex" }, { children: [_jsx(SelectField, { label: "\u0413\u043E\u0440\u043E\u0434", name: "city", value: cityState, options: cities, onChange: handlCityChange, defaultOption: "", isDisabled: isDisabled }), _jsx(SelectField, { label: "\u0416\u041A", name: "location", value: locationState, options: locations, onChange: handlLocationChange, defaultOption: "", isDisabled: isDisabled }), _jsx(SelectField, { label: "\u0414\u043E\u043C", name: "house", value: houseState, options: houses, onChange: handlHouseChange, defaultOption: "", isDisabled: isDisabled })] })));
};
export default ComboSpaceSelect;
