var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LockDocumentApi, UnlockDocumentApi } from "../../../libs/lightApi";
import { ROUTES } from "../types/Routes";
import { AuthorizedUser } from "./AuthorizedUser";
const LOCKING_PATHS = [
    ROUTES.HOUSE_EDIT,
    ROUTES.LOCATION_EDIT,
    ROUTES.OPD_RDIT,
    ROUTES.USER_RDIT,
    ROUTES.RESIDENT_EDIT,
    ROUTES.SPACE_EDIT
];
export class LockProcessor {
    static log(locks) {
        locks.map(l => {
            console.log(`Locking ${l.className}.${l.id}`);
        });
    }
    static lock(locks) {
        return __awaiter(this, void 0, void 0, function* () {
            this.log(locks);
            yield AuthorizedUser.getRequest(LockDocumentApi.path, "POST")
                .send({ locks });
        });
    }
    static unlock() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log("Unlocking all locked documents");
            yield AuthorizedUser.getRequest(UnlockDocumentApi.path, "POST")
                .send({});
        });
    }
    static unlockIfLocked(path) {
        return __awaiter(this, void 0, void 0, function* () {
            if (LOCKING_PATHS.some(p => path.startsWith(p))) {
                return this.unlock();
            }
        });
    }
}
