export var LegendaRecordImportType;
(function (LegendaRecordImportType) {
    LegendaRecordImportType["RESIDENT"] = "\u0424\u0438\u0437\u0438\u0447\u0435\u0441\u043A\u043E\u0435 \u043B\u0438\u0446\u043E";
    LegendaRecordImportType["BUISNESS"] = "\u042E\u0440\u0438\u0434\u0438\u0447\u0435\u0441\u043A\u043E\u0435 \u043B\u0438\u0446\u043E";
})(LegendaRecordImportType || (LegendaRecordImportType = {}));
export var LegendaResidentImportKeys;
(function (LegendaResidentImportKeys) {
    LegendaResidentImportKeys[LegendaResidentImportKeys["EXT_ID"] = 0] = "EXT_ID";
    LegendaResidentImportKeys[LegendaResidentImportKeys["TYPE"] = 1] = "TYPE";
    LegendaResidentImportKeys[LegendaResidentImportKeys["ORGANIZATION_NAME"] = 2] = "ORGANIZATION_NAME";
    LegendaResidentImportKeys[LegendaResidentImportKeys["ORGANIZATION_INN"] = 3] = "ORGANIZATION_INN";
    LegendaResidentImportKeys[LegendaResidentImportKeys["LASTNAME"] = 4] = "LASTNAME";
    LegendaResidentImportKeys[LegendaResidentImportKeys["PREVIOUS_LASTNAME"] = 5] = "PREVIOUS_LASTNAME";
    LegendaResidentImportKeys[LegendaResidentImportKeys["FIRSTNAME"] = 6] = "FIRSTNAME";
    LegendaResidentImportKeys[LegendaResidentImportKeys["MIDDLENAME"] = 7] = "MIDDLENAME";
    LegendaResidentImportKeys[LegendaResidentImportKeys["SEX"] = 8] = "SEX";
    LegendaResidentImportKeys[LegendaResidentImportKeys["BIRTH_DATE"] = 9] = "BIRTH_DATE";
    LegendaResidentImportKeys[LegendaResidentImportKeys["SNILS"] = 10] = "SNILS";
    LegendaResidentImportKeys[LegendaResidentImportKeys["INN"] = 11] = "INN";
    LegendaResidentImportKeys[LegendaResidentImportKeys["EMAIL"] = 12] = "EMAIL";
    LegendaResidentImportKeys[LegendaResidentImportKeys["PHONE"] = 13] = "PHONE";
    LegendaResidentImportKeys[LegendaResidentImportKeys["ADDITIONAL_PHONE"] = 14] = "ADDITIONAL_PHONE";
    LegendaResidentImportKeys[LegendaResidentImportKeys["APP_ACCESS"] = 15] = "APP_ACCESS";
    LegendaResidentImportKeys[LegendaResidentImportKeys["ADDITIONAL_INFO"] = 16] = "ADDITIONAL_INFO";
    LegendaResidentImportKeys[LegendaResidentImportKeys["HOBBY"] = 17] = "HOBBY";
    LegendaResidentImportKeys[LegendaResidentImportKeys["DOCUMENT_NAME"] = 18] = "DOCUMENT_NAME";
    LegendaResidentImportKeys[LegendaResidentImportKeys["DOCUEMNT_SERIES"] = 19] = "DOCUEMNT_SERIES";
    LegendaResidentImportKeys[LegendaResidentImportKeys["DOCUMENT_NUMBER"] = 20] = "DOCUMENT_NUMBER";
    LegendaResidentImportKeys[LegendaResidentImportKeys["DOCUMENT_ISSUED_BY"] = 21] = "DOCUMENT_ISSUED_BY";
    LegendaResidentImportKeys[LegendaResidentImportKeys["DOCUMENT_ISSUING_DATE"] = 22] = "DOCUMENT_ISSUING_DATE";
    LegendaResidentImportKeys[LegendaResidentImportKeys["SPACE_ID"] = 23] = "SPACE_ID";
    LegendaResidentImportKeys[LegendaResidentImportKeys["SPACE"] = 24] = "SPACE";
    LegendaResidentImportKeys[LegendaResidentImportKeys["SPACE_OWNERSHIP"] = 25] = "SPACE_OWNERSHIP";
    LegendaResidentImportKeys[LegendaResidentImportKeys["SPACE_SHARE"] = 26] = "SPACE_SHARE";
    LegendaResidentImportKeys[LegendaResidentImportKeys["SPACE_STATUS"] = 27] = "SPACE_STATUS";
    LegendaResidentImportKeys[LegendaResidentImportKeys["SPACE_DOCUMENT_NAME"] = 28] = "SPACE_DOCUMENT_NAME";
    LegendaResidentImportKeys[LegendaResidentImportKeys["SPACE_DOCUMENT_NUMBER"] = 29] = "SPACE_DOCUMENT_NUMBER";
    LegendaResidentImportKeys[LegendaResidentImportKeys["SPACE_DOCUMENT_DATE"] = 30] = "SPACE_DOCUMENT_DATE";
    LegendaResidentImportKeys[LegendaResidentImportKeys["SPACE_DOCUMENT_COMMENT"] = 31] = "SPACE_DOCUMENT_COMMENT";
})(LegendaResidentImportKeys || (LegendaResidentImportKeys = {}));
export var LegendaSpaceImportKeys;
(function (LegendaSpaceImportKeys) {
    LegendaSpaceImportKeys[LegendaSpaceImportKeys["HOUSE_MONGO_ID"] = 0] = "HOUSE_MONGO_ID";
    LegendaSpaceImportKeys[LegendaSpaceImportKeys["HOUSE_NAME"] = 1] = "HOUSE_NAME";
    LegendaSpaceImportKeys[LegendaSpaceImportKeys["EXT_ID"] = 2] = "EXT_ID";
    LegendaSpaceImportKeys[LegendaSpaceImportKeys["MONGO_ID"] = 3] = "MONGO_ID";
    LegendaSpaceImportKeys[LegendaSpaceImportKeys["NAME"] = 4] = "NAME";
    LegendaSpaceImportKeys[LegendaSpaceImportKeys["TYPE"] = 5] = "TYPE";
    LegendaSpaceImportKeys[LegendaSpaceImportKeys["NUMBER"] = 6] = "NUMBER";
    LegendaSpaceImportKeys[LegendaSpaceImportKeys["CADASTRAL_NUMBER"] = 7] = "CADASTRAL_NUMBER";
    LegendaSpaceImportKeys[LegendaSpaceImportKeys["PORCH"] = 8] = "PORCH";
    LegendaSpaceImportKeys[LegendaSpaceImportKeys["FLOOR"] = 9] = "FLOOR";
    LegendaSpaceImportKeys[LegendaSpaceImportKeys["SQUARE"] = 10] = "SQUARE";
    LegendaSpaceImportKeys[LegendaSpaceImportKeys["SPACE_TYPE"] = 11] = "SPACE_TYPE";
    LegendaSpaceImportKeys[LegendaSpaceImportKeys["COMMERSIAL_TYPE"] = 12] = "COMMERSIAL_TYPE";
})(LegendaSpaceImportKeys || (LegendaSpaceImportKeys = {}));
