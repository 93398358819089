var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { parseDate } from '../../../../../libs/utils';
import { getNameFromPersonalData } from '../../../../../libs/utils/src/strings/namingUtils';
import { AuthorizedUser } from '../../../logic/AuthorizedUser';
import OpdConsentsFilter from './OpdConsentsFilter';
import OpdConsentsTableBody from './OpdConsentsTableBody';
import OpdConsentsTableHeader from './OpdConsentsTableHeader';
const OpdConsentsView = () => {
    const [consentsOPD, setConsentsOPD] = useState([]);
    const [filters, setFilters] = useState({});
    const [sortParams, setSortParams] = useState({
        column: '',
        direction: '-1',
    });
    const fetchDpa = (ids) => {
        if (!Array.isArray(ids) || !ids.length)
            return [];
        const req = AuthorizedUser.getRequest('/dpa');
        req.params.ids = ids.join(',');
        return req.send();
    };
    const fetchDpa2a = () => {
        const req = AuthorizedUser.getRequest('/dpa2a');
        if (filters.status)
            req.params.status = filters.status;
        if (sortParams.column) {
            req.params.order = (sortParams.direction === '-1' ? '-' : '') + sortParams.column;
        }
        return req.send();
    };
    const fetchResidents = (ids) => {
        if (!Array.isArray(ids) || !ids.length)
            return [];
        const req = AuthorizedUser.getRequest('/residents');
        req.params.ids = ids.join(',');
        return req.send();
    };
    const fetchR2f = (ids) => {
        if (!Array.isArray(ids) || !ids.length)
            return [];
        const req = AuthorizedUser.getRequest('/r2fs');
        req.params.access = ids.join(',');
        return req.send();
    };
    const fetchConsents = () => __awaiter(void 0, void 0, void 0, function* () {
        const dpas2as = yield fetchDpa2a();
        const dpas = yield fetchDpa(dpas2as.map(d => d.dpa));
        const r2fs = yield fetchR2f(dpas2as.map(d => d.access));
        const residents = yield fetchResidents(r2fs.map(r => r.resident));
        const consents = dpas2as.map((dpa2a) => {
            const dpa = dpas.find((d) => d.id === dpa2a.dpa);
            const r2f = r2fs.find((r2f) => r2f.access === dpa2a.access);
            if (!r2f || !dpa)
                return;
            const resident = residents.find((res) => res.id === r2f.resident);
            if (!resident)
                return;
            return {
                id: dpa2a.id,
                version: dpa.version || '',
                type: dpa.type || '',
                versionDate: parseDate(dpa.versionDate),
                device: '',
                status: dpa2a.status,
                activateAt: parseDate(dpa.activateAt),
                name: getNameFromPersonalData(resident),
                updated_at: dpa2a.updateStmp,
                created_at: dpa2a.createStmp,
            };
        }).filter(c => !!c);
        setConsentsOPD(consents);
    });
    useEffect(() => {
        fetchConsents();
    }, [sortParams]);
    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
    };
    const handleSortChange = (column) => {
        setSortParams((prevSortParams) => ({
            column,
            direction: prevSortParams.column === column && prevSortParams.direction === '-1' ? '-' : '',
        }));
    };
    return (_jsxs("div", Object.assign({ id: "documentsOPD-table-view" }, { children: [_jsx(OpdConsentsFilter, { filters: filters, onFilterChange: handleFilterChange, onSubmit: fetchConsents }), _jsxs("table", Object.assign({ id: "table-opd-documents" }, { children: [_jsx(OpdConsentsTableHeader, { onSortChange: handleSortChange, sortParams: sortParams }), _jsx(OpdConsentsTableBody, { consentsOPD: consentsOPD })] }))] })));
};
export default OpdConsentsView;
