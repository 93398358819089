var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { AuthorizedUser } from '../logic/AuthorizedUser';
const MultipleTagsSelect = ({ selected, onChange, }) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const tags = yield getTags();
                setOptions(tags);
            }
            catch (error) {
                console.error('Не удалось загрузить:', error);
            }
        });
        fetchData();
    }, []);
    function getTags() {
        return __awaiter(this, void 0, void 0, function* () {
            const req = AuthorizedUser.getRequest('/map-object', 'get');
            req.params.name = 'tags';
            const response = yield req.send();
            const tags = response.map((tag) => ({
                text: tag.value,
                value: tag.id,
            }));
            return tags;
        });
    }
    const handleAddTag = (value) => {
        onChange([...selected, value]);
    };
    const handleRemoveTag = (value) => {
        onChange(selected.filter((s) => s !== value));
    };
    return (_jsx("div", Object.assign({ className: "form-select" }, { children: _jsxs("div", Object.assign({ className: "multiple-select", onMouseOver: (e) => {
                var _a;
                return (_a = e.currentTarget
                    .querySelector('.multiple-select-options')) === null || _a === void 0 ? void 0 : _a.removeAttribute('hidden');
            }, onMouseLeave: (e) => {
                var _a;
                return (_a = e.currentTarget
                    .querySelector('.multiple-select-options')) === null || _a === void 0 ? void 0 : _a.setAttribute('hidden', '');
            } }, { children: [_jsx("div", Object.assign({ className: "multiple-select-selected" }, { children: selected.map((value) => {
                        var _a;
                        return (_jsxs("div", Object.assign({ className: "round-button pointer", onClick: () => handleRemoveTag(value) }, { children: [(_a = options.find((o) => o.value === value)) === null || _a === void 0 ? void 0 : _a.text, _jsx("div", Object.assign({ className: "multiple-input-remove" }, { children: "x" }))] }), value));
                    }) })), _jsxs("div", Object.assign({ hidden: true, className: "multiple-select-options" }, { children: [_jsx("div", { className: "horizontal-divider" }), _jsx("div", Object.assign({ className: "multiple-select-container" }, { children: options
                                .filter((option) => !selected.includes(option.value))
                                .map((option) => (_jsx("div", Object.assign({ className: "round-button pointer", onClick: () => handleAddTag(option.value) }, { children: option.text }), option.value))) }))] }))] })) })));
};
export default MultipleTagsSelect;
