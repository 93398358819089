import { jsx as _jsx } from "react/jsx-runtime";
const FlatTypeSelector = ({ value, options, onChange, isDisabled, }) => {
    const handleClick = (optionValue) => {
        if (!isDisabled) {
            onChange(optionValue);
        }
    };
    return (_jsx("div", Object.assign({ className: "flat-type-selector" }, { children: _jsx("div", Object.assign({ className: "flat-type-options" }, { children: options.map((option) => (_jsx("div", Object.assign({ className: `flat-type-option ${value === option.value ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`, onClick: () => handleClick(option.value) }, { children: option.label }), option.value))) })) })));
};
export default FlatTypeSelector;
