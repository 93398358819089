import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useEffect, useId, useRef, useState } from "react";
import { Link, useMatch } from "react-router-dom";
import { RoleType } from "../../../libs/types";
import { doesSatisfyTheRole } from "../../../libs/utils/src/roles/roleUtils";
import { AuthorizedUser } from "../logic/AuthorizedUser";
import { processElement } from "../logic/HtmxUtils";
import { UserCard } from "./UserCard";
import { ROUTES } from "../types/Routes";
import { toSelected } from "../../../libs/utils/src/html/ClassNameUtils";
export const TopMenu = () => {
    const ref = useRef(null);
    const isOnResidents = useMatch({ path: ROUTES.RESIDENTS_REGISTER, end: false });
    const isOnObjects = useMatch({ path: ROUTES.OBJECTS_REGISTER, end: false });
    const isOnReports = useMatch({ path: ROUTES.REPORTS, end: false });
    const isOnAdmin = useMatch({ path: ROUTES.ADMIN, end: false });
    const FAQ_LINK = 'https://portal.legenda-dom.ru/employees/Lists/mkd/fd_%D0%AD%D0%BB%D0%B5%D0%BC%D0%B5%D0%BD%D1%82_Display.aspx?List=b2083573-ed13-4e26-85b1-254aceb70153&ID=17&Source=https%3A%2F%2Fportal.legenda-dom.ru%2Femployees%2FLists%2Fmkd%2FAllItems.aspx&ContentTypeId=0x0100C22B29D9427991428FEE45D203CE616D';
    useEffect(() => {
        const current = ref.current;
        if (current) {
            processElement(current);
        }
    }, []);
    const [isScrolled, setIsScrolled] = useState(false);
    const [showMenu, setShowMenu] = useState(true);
    useEffect(() => {
        const current = ref.current;
        if (current) {
            processElement(current);
        }
    }, []);
    useEffect(() => {
        let previousScrollY = 0;
        const handleScroll = () => {
            const scrolledY = window.scrollY;
            if (isOnObjects || isOnResidents) {
                const scrolledBy = scrolledY - previousScrollY;
                if (scrolledY === 0) {
                    setShowMenu(true);
                }
                else if (scrolledBy < 0) {
                    setShowMenu(true);
                }
                else {
                    setShowMenu(false);
                }
            }
            setIsScrolled(scrolledY > 0);
            previousScrollY = scrolledY;
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    function renderMenu(path, title, selected) {
        return _jsx(Link, Object.assign({ className: `navigation ${toSelected(selected)}`, to: path }, { children: title }), useId());
    }
    function renderMenus() {
        return _jsxs(Fragment, { children: [renderMenu(ROUTES.RESIDENTS_REGISTER, "Реестр жителей", !!isOnResidents), renderMenu(ROUTES.OBJECTS_REGISTER, "Объекты недвижимости", !!isOnObjects), renderMenu(ROUTES.REPORTS, "Конструктор отчетов", !!isOnReports), doesSatisfyTheRole(RoleType.ADMIN, AuthorizedUser.instance.getRole()) ?
                    renderMenu(ROUTES.ADMIN, "Администрирование", !!isOnAdmin) :
                    null] });
    }
    return _jsxs("div", Object.assign({ id: "top-menu", ref: ref, className: (isOnObjects || isOnResidents) && !showMenu ? 'absolute' : 'fixed' }, { children: [_jsxs("div", Object.assign({ className: "flex align-center" }, { children: [_jsx("img", { id: "svg-logo", src: "/public/img/logo.svg", width: "105", height: "28" }), _jsxs("div", Object.assign({ id: "top-menu-button-row" }, { children: [renderMenus(), _jsx("a", Object.assign({ id: "faq", className: "navigation", href: FAQ_LINK, target: "_blank" }, { children: "FAQ" }))] }))] })), _jsx(UserCard, {})] }));
};
